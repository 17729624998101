import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './functions/ScrollToTop';
import Main from './pages/Main/Main';
import Form from './pages/Form/Form';
import Order from './pages/Order/Order';

export default function MainRoute() {
  return (
    <>
      {/* <ScrollToTop /> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Form />} />
        <Route path="/successful" element={<Order />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </>
  );
}
