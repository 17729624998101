import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Main.css';

export default function Main() {
  const [expandedId, setExpandedId] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const lineRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.2 }, // Триггерує, коли 20% елементу видно
    );

    const elements = document.querySelectorAll('.main__step-block--left, .main__step-block--right');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const lineElement = lineRef.current;

    const handleScrollAnimation = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animated');
        }
      });
    };

    const observer = new IntersectionObserver(handleScrollAnimation, {
      threshold: 0.5,
    });

    if (lineElement) {
      observer.observe(lineElement);
    }

    return () => {
      if (lineElement) observer.unobserve(lineElement);
    };
  }, []);

  const handleNavigate = () => {
    navigate('/login');
  };

  const handleToggle = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleAnswerToggle = (id) => {
    setAnswerId(answerId === id ? null : id);
  };

  const lessonList = [
    { text: 'Вступний Модуль 0 "Сутність фінансового менеджменту" та 7 основних модулів навчання.', id: 1 },
    {
      text: '8 групових лекцій з подачі матеріалу та 7 зідзвонів з перевірки домашнього завдання',
      id: 2,
    },
    {
      text: 'Простір з однодумцями (власниками бізнесу), які переживають ту ж ситуацію, що і ви.',
      id: 3,
    },
    {
      text: 'Ми разом з вами побудуємо систему звітів для вашого бізнесу.',
      id: 5,
    },
    {
      text: 'На кожному модулі ви поступово пройдете шлях до самостійного налаштування системи звітів та точно навчитеся оперувати цифрами.',
      id: 6,
    },
    {
      text: 'Весь цей шлях буде супроводжуватися домашнім завданням, його перевіркою та аналізом саме вашої ситуації.',
      id: 7,
    },
    {
      text: 'Навчитеся рахувати головні показники бізнесу і головне - їх аналізувати.',
      id: 8,
    },
    {
      text: 'За вами буде закріплений фінансовий менеджер, який додатково консультуватиме за потреби.',
      id: 9,
    },
  ];
  const structureList = [
    {
      title: 'Модуль 0. ТЕМА: Сутність фінансового обліку ',
      id: 8,
      text: ['Лекція "Вступ у фінанси бізнесу."', 'Після лекції ви отримуєте конспект.', 'Домашнє завдання у вигляді тесту.'],
    },
    {
      title: 'Модуль 1. ТЕМА: Категоризація бізнесу ',
      id: 1,
      text: [
        'Лекція по матеріалу і правилам структурування бізнесу.',
        'Після лекції ви отримуєте конспект та шаблони для заповнення структур.',
        'Обов’язкове домашнє завдання - побудова структури Вашого бізнесу.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
      ],
    },
    {
      title: 'Модуль 2. ТЕМА: Звіт CASH FLOW',
      id: 2,
      text: [
        'Лекція по матеріалу і правилам побудови звіту.',
        'Після лекції ви отримуєте конспект та шаблон структури звіту.',
        'Обов’язкове домашнє завдання - побудова структуру звіту Cahs Flow.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
      ],
    },
    {
      title: 'Модуль 3. ТЕМА: Звіт Profit & Loss',
      id: 3,
      text: [
        'Лекція по матеріалу та системі нарахування витрат.',
        'Після лекції ви отримуєте конспект та шаблон структури звіту.',
        'Обов’язкове домашнє завдання - побудова структури звіту P&L.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
      ],
    },
    {
      title: 'Модуль 4. ТЕМА: Собівартість',
      id: 4,
      text: [
        'Лекція по правилам нарахування собівартості. На лекції розбираємо системи crm та роботу в них.',
        'Після лекції ви отримуєте конспект та шпаргалку по процесу роботи із собівартістю.',
        'Обов’язкове домашнє завдання - побудова процесу визначення собівартості.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
      ],
    },
    {
      title: 'Модуль 5. ТЕМА: Заповнення даних',
      id: 5,
      text: [
        'Лекція по матеріалу і правилам внесення даних у звіт. ',
        'Рекоменцації по автоматизації даних.',
        'Після лекції ви отримуєте конспект та шпаргалку по заповненню звіту.',
        'Обов’язкове домашнє завдання - внесення даних бізнесу у звіти.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
      ],
    },
    {
      title: 'Модуль 6. ТЕМА: Коло рентабельності',
      id: 6,
      text: [
        'Лекція по матеріалу і побудові Кола рентабельності.',
        'Після лекції ви отримуєте конспект та шпаргалку по заповненню звіту.',
        'Обов’язкове домашнє завдання - побудова Кола рентабельності фактичного та еталонного.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
      ],
    },
    {
      title: 'Модуль 7. ТЕМА: Аналіз даних',
      id: 7,
      text: [
        'Лекція по правилах аналізу даних, основних коєфіцієнтів та їхньої важливості.',
        'Після лекції ви отримуєте конспект та шпаргалку-заключення (Фінансового аналізу).',
        'Обов’язкове домашнє завдання - заповнити фінансовий аналіз.',
        'Тут ви вже виступаєте “самі собі фінансистом”.',
        'Перевірка ДЗ та зворотній зв’язок із фінансистом.',
        'Рекомендації для вашого бізнесу.',
      ],
    },
  ];
  const questionsList = [
    {
      q: '1. Чи можлива оплата частинами?',
      a: 'Вартість бронювання місця – 1000 грн, яку буде віднято від загальної вартості курсу. Решту суми розділено на два платежі: перший необхідно внести перед початком навчання, а другий – у середині курсу.',
      id: 1,
    },
    {
      q: '2. Коли стартує навчання та яка його тривалість?',
      a: 'Старт навчання - 19 січня. Тривалість - 2 місяці.',
      id: 2,
    },
  ];

  return (
    <>
      <Header />
      <main className="main">
        <div className="main__top">
          <div className="main__top-text--wrap">
            <h1 className="main__top-title">Навчання</h1>
            <p className="main__top-title-text">"Сам собі фінансист"</p>
            <p className="main__top-title-text--next">Побудуй фінансові звіти свого бізнесу власноруч</p>
            <p className="main__top-text--small">Зроби перший крок від хаосу у фінансах до спокою за бізнес!</p>
            <button onClick={handleNavigate} className="main__top-btn">
              Забронювати зі знижкою 40%
            </button>
            <p className="main__top-btn-text">Тільки 7 днів 7 500 грн</p>
            <p className="main__top-btn-text main__top-btn-text--decoration">12 500 грн</p>
          </div>
        </div>
        <div className="main__step">
          <div className="main__step-reg">
            <div className="main__step-reg-arrow">
              <img src={!isMobile ? '/icon/arrow.svg' : '/icon/arrow-black.svg'} alt="arrow" />
            </div>
            <h2 className="main__step-reg-title">Реєструйся зараз та отримай бонуси!</h2>
            <p className="main__step-reg-text">
              <span className="main__step-reg-text-numb">1. </span>Шаблон операційного бюджету зі шпаргалками.
            </p>
            <p className="main__step-reg-text">
              <span className="main__step-reg-text-numb">2. </span>Безкоштовні консультації вашого бізнесу протягом 1 місяця.
            </p>
          </div>
          <div className="main__step-block">
            <section id="for-whom" class="anchor-target"></section>
            <h2 className="main__step-block-title">Вам просто необхідне це навчання, якщо ви:</h2>
            <div className="main__step-block-wrap">
              <div className="main__step-block--left z1">
                <div className="main__step-block--left-img">
                  <img src="/img/left-fold.svg" alt="fold" />
                </div>
                <div className="main__step-block-text-wrap ">
                  <p className="main__step-block-number">1.</p>
                  <p className="main__step-block-text">Не розумієте скільки заробляєте</p>
                </div>
              </div>
              <div className="main__step-block--right z2">
                <div className="main__step-block--left-img">
                  <img src="/img/right-fold.svg" alt="fold" />
                </div>
                <div className="main__step-block-text-wrap main__step-block-text-wrap--right">
                  <p className="main__step-block-number">2.</p>
                  <p className="main__step-block-text">Не знаєте cуми ваших витрат</p>
                </div>
              </div>
              <div className="main__step-block--left z3">
                <div className="main__step-block--left-img">
                  <img src="/img/left-fold.svg" alt="fold" />
                </div>
                <div className="main__step-block-text-wrap">
                  <p className="main__step-block-number">3.</p>
                  <p className="main__step-block-text">Не можете прорахувати прибутковість, самоокупність</p>
                </div>
              </div>
              <div className="main__step-block--right z4">
                <div className="main__step-block--left-img">
                  <img src="/img/right-fold.svg" alt="fold" />
                </div>
                <div className="main__step-block-text-wrap main__step-block-text-wrap--right">
                  <p className="main__step-block-number">4.</p>
                  <p className="main__step-block-text">Не знаєте свій резервний фонд та його цінність</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main__about">
          <section id="about-author" class="anchor-target"></section>
          <h2 className="main__about-title">Хто я?</h2>
          <div className="main__about-text-wrap">
            {!isMobile && (
              <div className="main__about-text-img-wrap">
                <div className="main__about-text-img"></div>
                <div className="main__about-text-wrap-text">
                  <h2 className="main__about-text-title">Аліна Мікшіна</h2>
                  <p className="main__about-text">
                    Фінансистка та екпертка з фінансів бізнесу. Власниця диплому ACPM з Фінансового менеджменту. Розповідаю про бізнес простими словами. У сфері фінансів працюю вже
                    10 років.
                  </p>
                  <h3 className="main__about-list-title">Мої принципи в роботі:</h3>
                  <ul className="main__about-list">
                    <li className="main__about-list-item">
                      <div className="main__about-list-item-icon">
                        <img src="/icon/notepag-black.svg" alt="notepad" />
                      </div>
                      <p className="main__about-list-item-text">Звіти створені для максимізації прибутку</p>
                    </li>
                    <li className="main__about-list-item main__about-list-item--big">
                      <div className="main__about-list-item-icon">
                        <img src="/icon/bank-black.svg" alt="bank" />
                      </div>
                      <p className="main__about-list-item-text">Фінанси мають бути простими та зрозумілими для власника</p>
                    </li>
                    <li className="main__about-list-item">
                      <div className="main__about-list-item-icon">
                        <img src="/icon/ok-black.svg" alt="ok" />
                      </div>
                      <p className="main__about-list-item-text">Точність та якість на першому місці</p>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="main__about-text-title--wrap">
                <h2 className="main__about-text-title">Аліна Мікшіна</h2>
                <p className="main__about-text">
                  Фінансистка та екпертка з фінансів бізнесу. Власниця диплому ACPM з Фінансового менеджменту. Розповідаю про бізнес простими словами. У сфері фінансів працюю вже
                  10 років.
                </p>
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="">
            <h3 className="main__about-list-title">Мої принципи в роботі:</h3>
            <ul className="main__about-list">
              <li className="main__about-list-item">
                <div className="main__about-list-item-icon">
                  <img src={!isMobile ? '/icon/notepag.svg' : '/icon/notepag-black.svg'} alt="notepad" />
                </div>
                <p className="main__about-list-item-text">Звіти створені для максимізації прибутку</p>
              </li>
              <li className="main__about-list-item main__about-list-item--big">
                <div className="main__about-list-item-icon">
                  <img src={!isMobile ? '/icon/bank.svg' : '/icon/bank-black.svg'} alt="bank" />
                </div>
                <p className="main__about-list-item-text">Фінанси мають бути простими та зрозумілими для власника</p>
              </li>
              <li className="main__about-list-item">
                <div className="main__about-list-item-icon">
                  <img src={!isMobile ? '/icon/ok.svg' : '/icon/ok-black.svg'} alt="ok" />
                </div>
                <p className="main__about-list-item-text">Точність та якість на першому місці</p>
              </li>
            </ul>
          </div>
        )}
        {isMobile && (
          <div className="main__photo-mob">
            <img src="/img/3inst.jpg" alt="notepad" />
          </div>
        )}
        <div className="main__lesson">
          <section id="about-intensive" class="anchor-target"></section>
          <h2 className="main__lesson-title">Що ви отримаєте на навчанні:</h2>
          <ul className="main__lesson-list">
            {lessonList.map((item) => {
              return (
                <li className="main__lesson-list-item" key={item.id}>
                  <div className="main__lesson-list-item-icon">
                    <img src="/icon/ok-orange.svg" alt="ok" />
                  </div>
                  <div className="main__lesson-list-item-text">{item.text}</div>
                </li>
              );
            })}
          </ul>
        </div>
        {!isMobile && (
          <div className="main_center-img">
            <img src="/img/3desck.jpg" alt="table" />
          </div>
        )}
        <div className="main__structure">
          <section id="structure" class="anchor-target"></section>
          <h2 className="main__structure-title">Структура навчання:</h2>
          <ul className="main__structure-list">
            {structureList.map((item) => {
              return (
                <li className="main__structure-list-item" onClick={() => handleToggle(item.id)}>
                  <p className={`main__structure-list-item--anim ${expandedId === item.id ? 'rotate' : 'rotate-back'}`}>+</p>
                  <p className="main__structure-list-item-title">{item.title}</p>
                  <ul className={`main__structure-list-item-text--wrap ${expandedId === item.id ? 'open' : ''}`}>
                    {item.text.map((text) => {
                      return <li className="main__structure-list-item-text">{text}</li>;
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="main__teach">
          <section id="results" class="anchor-target"></section>
          <h2 className="main__teach-title">Яким буде кінцевий результат навчання?</h2>
          <div className="main__teach-block-wrap">
            <div className="main__teach-block main__teach-block--up">
              <p className="main__teach-block-text">Готова структура бізнесу.</p>
              <div className="main__teach-block-cicle">1</div>
              <div className="main__teach-block-line--1"></div>
            </div>
            <div className="main__teach-block main__teach-block--down">
              <div className="main__teach-block-cicle">2</div>
              <p className="main__teach-block-text">Cash Flow, P&L, Коло рентабельності</p>
              <div className="main__teach-block-line--2"></div>
            </div>
            <div className="main__teach-block">
              <p className="main__teach-block-text">Місячна безкоштовна підтримка.</p>
              <div className="main__teach-block-cicle">3</div>
              <div className="main__teach-block-line--3"></div>
            </div>
            <div className="main__teach-block main__teach-block--down">
              <div className="main__teach-block-cicle">4</div>
              <p className="main__teach-block-text">Операційний бюджет, який дозволить жити спокійно та не хвилюватися за бізнес.</p>
              <div className="main__teach-block-line--4"></div>
            </div>
            <div className="main__teach-block">
              <p className="main__teach-block-text">І нові знання, щоб ефективно управляти бізнесом.</p>
              <div className="main__teach-block-cicle">5</div>
              <div className="main__teach-block-line--5"></div>
            </div>
          </div>
        </div>
        <div className="main__question">
          <h2 className="main__question-title">Найпоширеніші питання</h2>
          <ul className="main__question-list">
            {questionsList.map((item) => (
              <li key={item.id} className="main__question-list-item" onClick={() => handleAnswerToggle(item.id)}>
                <p className={`main__question-list-item--anim ${answerId === item.id ? 'rotate' : 'rotate-back'}`}>+</p>
                <div className="main__question-list-item--q">{item.q}</div>
                <div className={`main__question-list-item--a ${answerId === item.id ? 'show' : ''}`}>{item.a}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="main_bottom">
          <div className="main_bottom-btn-wrap">
            <button className="main_bottom-btn" onClick={handleNavigate}>
              Забронювати зі знижкою 40%
            </button>
          </div>
          {!isMobile && (
            <div className="main_bottom-img">
              <img src="/img/3inst.jpg" alt="table" />
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}
