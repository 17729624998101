import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Context } from "./Context";
import MainRoute from "./MainRoute";


function App() {
  const [formActive, setFormActive] = useState(false);

  return (
    <Context.Provider
      value={{
        formActive,
        setFormActive,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainRoute />} />
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
