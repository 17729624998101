import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import './Header.css';

export default function Header() {
  // const [openBurger, setOpenBurger] = useState(false);
  // const [animateExit, setAnimateExit] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // useEffect(() => {
  //   if (openBurger) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = '';
  //   }
  // }, [openBurger]);

  // const handleBurgerClick = () => {
  //   if (openBurger) {
  //     setAnimateExit(true);
  //     setTimeout(() => {
  //       setOpenBurger(false);
  //       setAnimateExit(false);
  //     }, 500);
  //   } else {
  //     setOpenBurger(true);
  //   }
  // };

  // const handleNavLinkClick = () => {
  //   setAnimateExit(true);
  //   setTimeout(() => {
  //     setOpenBurger(false);
  //     setAnimateExit(false);
  //     document.body.style.overflow = ''; // Повертаємо прокрутку
  //   }, 500);
  // };

  return (
    <header className="header">
      {isMobile && (
        <div className="header__mobile">
          <a className="footer__list-item-icon" href="https://t.me/Alina_Mikshin" target="_blank" rel="noopener noreferrer">
            <img src="/icon/telegram.svg" alt="telegram" />
          </a>
          <a className="footer__list-item-icon" href="tel:+380939941301">
            <img src="/icon/phone.svg" alt="phone" />
          </a>
          <a className="footer__list-item-icon" href="https://www.instagram.com/fin_mikshina/" target="_blank" rel="noopener noreferrer">
            <img src="/icon/instagram.svg" alt="instagram" />
          </a>
        </div>
      )}
      {/* <div onClick={handleBurgerClick} className="header__burger">
        <div className={`header__burger-line-one ${openBurger ? 'header__burger-line-one-active' : ''}`}></div>
        <div className={`header__burger-line-two ${openBurger ? 'header__burger-line-two-open-one' : ''}`}></div>
        <div className={`header__burger-line-two ${openBurger ? 'header__burger-line-two-open-two' : ''}`}></div>
        <div className={`header__burger-line-three ${openBurger ? 'header__burger-line-one-active' : ''}`}></div>
      </div> */}

      {!isMobile && (
        <ul className="header__list">
          <li className="header__list-item">
            <a href="#for-whom">Для кого?</a>
          </li>
          <li className="header__list-item">
            <a href="#about-author">Про авторку</a>
          </li>
          <li className="header__list-item">
            <a href="#about-intensive">Про інтенсив</a>
          </li>
          <li className="header__list-item">
            <a href="#structure">Структура інтенсиву</a>
          </li>
          <li className="header__list-item">
            <a href="#results">Результати</a>
          </li>
        </ul>
      )}

      {/* {openBurger && isMobile && (
        <div className={`header__modal ${animateExit ? 'header__modal-exit' : ''}`}>
          <ul className="header__modal-list">
            <li className="header__modal-item">
              <a href="#for-whom" onClick={handleNavLinkClick}>
                Для кого?
              </a>
            </li>
            <li className="header__modal-item">
              <a href="#about-author" onClick={handleNavLinkClick}>
                Про авторку
              </a>
            </li>
            <li className="header__modal-item">
              <a href="#about-intensive" onClick={handleNavLinkClick}>
                Про інтенсив
              </a>
            </li>
            <li className="header__modal-item">
              <a href="#structure" onClick={handleNavLinkClick}>
                Структура інтенсиву
              </a>
            </li>
            <li className="header__modal-item">
              <a href="#results" onClick={handleNavLinkClick}>
                Результати
              </a>
            </li>
          </ul>
        </div>
      )} */}
    </header>
  );
}
