import React from 'react';
import './Order.css';
import { Link } from 'react-router-dom';

export default function Form() {
  return (
    <>
      <div className="form__header"></div>
      <div className="order">
        <div className="order-wrap">
          <div className="order__title">Ваша заявка успішно прийнята!</div>
          <div className="order__icon">
            <img src="/icon/ok-orange.svg" alt="ok" />
          </div>
          <Link to={'/'} className="order__btn">
            На головну
          </Link>
        </div>
      </div>
    </>
  );
}
