import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      <ul className="footer__list">
        <li className="footer__list-item">Залишились питання? Ви можете зв’язатись зі мною будь-яким зручним способом!</li>
        <li className="footer__list-item">
          <a className="footer__list-item--mail" href="/">
            alinamikshina2024@gmail.com
          </a>
        </li>
        <li className="footer__list-item footer__list-item--icon">
          <a className="footer__list-item-icon" href="https://t.me/Alina_Mikshin" target="_blank" rel="noopener noreferrer">
            <img src="/icon/telegram.svg" alt="telegram" />
          </a>
          <a className="footer__list-item-icon" href="tel:+380939941301">
            <img src="/icon/phone.svg" alt="phone" />
          </a>
          <a className="footer__list-item-icon" href="https://www.instagram.com/fin_mikshina/" target="_blank" rel="noopener noreferrer">
            <img src="/icon/instagram.svg" alt="instagram" />
          </a>
        </li>
      </ul>
    </footer>
  );
}
