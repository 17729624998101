import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../components/Footer/Footer';
import './Form.css';

export default function Form() {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    phone: '',
  });
  const [dateTime, setDateTime] = useState('');
  const navigate = useNavigate();

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Місяці починаються з 0
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    setDateTime(getFormattedDateTime());
  }, []);

  const fields = [
    { label: 'ПІБ', name: 'userName', type: 'text' },
    { label: 'Електронна пошта', name: 'email', type: 'email' },
    { label: 'Телефон', name: 'phone', type: 'tel' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      'Нове повідомлення': dateTime,
      ПІБ: formData.userName,
      Email: formData.email,
      Телефон: formData.phone,
    };
    await axios.post('https://military-backend.onrender.com/maksTelegram', data);
    navigate('/successful');
  };

  return (
    <>
      <div className="form__header"></div>
      <div className="form">
        <Link to={'/'} className="form__back">
          <div className="form__back-img">
            <img src="/icon/arrow-back.svg" alt="Назад" />
          </div>
          <div className="form__back-text">Назад</div>
        </Link>
        <p className="form__title">Зареєструватись на навчання</p>
        <form className="form-wrap" onSubmit={handleSubmit}>
          {fields.map((field) => (
            <input
              key={field.name}
              type={field.type}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              placeholder={field.label}
              className="form__input"
              required
            />
          ))}
          <button type="submit" className="form__btn">
            Відправити
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}
